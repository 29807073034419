import React from "react";
function UnderConstruction()
{
    return(
        <div className="underconstruction" style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
            <img src='https://res.cloudinary.com/dqbxgjov1/image/upload/v1710615212/rnudnx71emho5avyoizc.png' height={300} width={300} />
            <h2>WebPage Under Construction</h2>
        </div>
    );
}
export default UnderConstruction;