import React from 'react'
import FAQ from './FAQ_Component';

import FAQ_Component from './FAQ_Component';
function HowtoOrder() {
  const faqList = [
    {
      question: "Do I need to create an account first before ordering online?",
      answer: " Yes, to place an order, it's necessary to register with your mobile number. However, feel free to browse our website without registering. Creating an account provides advantages like order tracking, access to past orders, and more. Rest assured, your personal information is handled securely, and we prioritize your privacy. Explore the convenience of seamless online shopping with us."
    },
    {
      question: "How do I create an account?",
      answer: "Go to the profile icon and register with your mobile number."
    },
    {
      question: "Can I have multiple accounts?",
      answer: "Each contact phone number can only be associated with only one account."
    },
    {
      question: " Do you deliver in my area? How do I check?",
      answer: "You can check it with your pin code."
    },
    {
      question: "What is the minimum cart order value?",
      answer: "Minimum cart order value is Rs.500/- ."
    },
    {
      question: "What are the delivery slots?",
      answer: "At Finnofarms.in, we offer convenient delivery slots from 8:00 am to 2:00 pm. While we strive for punctuality, please note that delivery times may be subject to occasional delays caused by factors beyond our control, such as traffic congestion, roadblocks, or unforeseen natural calamities. Your satisfaction is our priority, and we appreciate your understanding as we work to ensure a seamless delivery experience."
    },
    {
      question: "How do I change my shipping address?",
      answer: "After place an order you can’t change your shipping address. Please contact our customer support team for any modification of order."
    },
    {
      question: "Can I add or remove products after placing my order?",
      answer: "Once you have placed your order you will not be able to make modifications on the website. Please contact our customer support team for any modification of order.",
    },
    {
      question: "How do I check the current status of my order?",
      answer: "You can check the status of your order in our website. Go to Your Account -> My Orders -> Order details for the particular order ",
    },
    {
      question: "How do I give feedback or suggestions?",
      answer: "We would love to hear about your experience & your valuable suggestions. You can write to us after delivered your order.",
    },
    {
      question: "What are the benefits of buying from Finno Farms?",
      answer: "Delivery of premium quality fresh fish, fruits and vegetables with QR code traceability. Traceability 100% guarantees premium high-quality taste. Buying from us means buying directly from farmers without the presence of any middle agents, long food miles and at substantial market rates.",
    },
    {
      question: "What makes Finno Farms different from other suppliers?",
      answer: "Finno Farms delivers premium-quality fresh fish, fruits, and vegetables directly to your doorstep, ensuring excellence in taste and nutrition.",
    },
    {
      question: "How can I trust the quality of the products?",
      answer: "Our innovative QR code traceability system guarantees 100% traceability. Scan the code to know the origin and journey of your produce, ensuring premium quality.",
    },
    {
      question: "Why should I choose Finno Farms over local markets?",
      answer: "Buying from Finno Farms means direct sourcing from farmers without middle agents, reducing food miles and offering competitive market rates.",
    },
    {
      question: "What are the unique benefits of shopping with Finno Farms?",
      answer: "Finno Farms provides a farm-to-table experience, supporting local farmers, ensuring premium quality, and offering transparent traceability. Taste the Finno Farms difference today!",
    },
    {
      question: "How is Finno Farms different from other platform?",
      answer: "We provide the convenience of buying online farm fresh fish, fruits and vegetables that is sustainably grown and procured directly from farmers. Our QR traceability enabled delivery right at your doorstep with replacement guarantee ensures that we are true to our commitments.",
    },
    {
      question: "Does Finno Farms take bulk orders?",
      answer: "Yes, we can arrange special pricing and deliveries for bulk orders. Please call us on our number +91 8100533280 and mail at finnofarms@gmail.com to get a special pricing quote on bulk orders. We offer a minimum flat 10% discount on bulk purchases.",
    },
    {
      question: "Can I place the order over the phone?",
      answer: "Yes!! Simply call our number +91 810053328 and place the order with our executive on the phone. You can also place orders over mail and or over our Whatsapp.",
    },
    {
      question: "Is there any extra delivery charges?",
      answer: "Delivery charge will be Rs. 69/- for cart orders between Rs. 500 – 1499.",
    },
    {
      question: "Does Finno Farms fish have a foul odour and fishy smell?",
      answer: "No, Finnofarms.in only supplies the best and 100% freshest fish. Foul odor is existent when the fish is of sub-standard quality.",
    },
    {
      question: "Are the products delivered by Finno Farms guaranteed for 100% freshness and quality?",
      answer: "Yes, Finnofarms.in products are guaranteed for 100% freshness and superior quality. All our products are purchased directly from the source and utmost precaution is taken to maintain the freshness.",
    },
    {
      question: "How do I get my money back in case I return wrong delivered products?",
      answer: "If the payment has been made online then the money will be credited to your account & for Cash on Delivery orders, you won't be required to pay for the wrongly delivered item. You will only pay for the items you intend to purchase.",
    },
    {
      question: "What will I do in case I am not satisfied with the delivered products?",
      answer: "If you are not satisfied with the delivered products, Our delivery person will ensure on-the-spot verification to confirm your issue.",
    },
    {
      question: "How are the fish, vegetables, fruits weighed?",
      answer: "The fish is weighed before cutting, cleaning & descaling. After cutting, cleaning & descaling the actual weight of the fish will be less than the weight shown in the website/app. Weight loss varies from 10-35% in respect to different fishes. In the case of fruits, vegetables weight will be exact or greater than the weight shown. We do not charge any extra money for higher in weight than the weight shown in the website/app",
    },
    {
      question: "I am a corporate/business, can I order with finnofarms.in?",
      answer: "Yes, we do bulk supply of products at special prices to institutions like schools, restaurants and corporations. Please contact us at +91 810053328  to know more.",
    },
  ];
  return (
    <>
    
    <div style={{fontSize:"135%", marginBottom:"15vh"}}>
      <h2>Frequently Asked Questions(FAQs)</h2>
      <div>
        {faqList.map((faq, index) => (
          <FAQ_Component key={index} question={faq.question} answer={faq.answer} />
        ))}
      </div>
    </div>
    </>
  )
}

export default HowtoOrder