import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ImageUpload from "./ImageUpload";
import './App.css';


//import links for ADMIN section
import FruitVeggieUploadForm from "./Admin/FruitVeggieUploadForm";
import FishUploadForm from "./Admin/FishUploadForm";
import FinnoSpecialUploadForm from "./Admin/FinnoSpecialUploadForm";
import ViewProducts from "./Admin/ViewProducts";
import EditProduct from "./Admin/EditProduct";
import AdminHomePage from "./Admin/AdminHomePage";
import CurrentOrders from "./Admin/CurrentOrders";
import ApprovedOrders from "./Admin/ApprovedOrders";
import UnderConstruction from "./UnderConstruction";
import AdminLogin from "./Admin/AdminLogin";
import AdminRoute from "./Admin/AdminRoute";
import CancelledOrders from "./Admin/CancelledOrders";
import DeliveredOrders from "./Admin/DeliveredOrders";
import RefundList from "./Admin/RefundList";
import RefundedOrders from "./Admin/RefundedOrders";
import IssueRefundForm from "./Admin/IssueRefundForm";

import Footer from "./Footer/Footer";
import HowtoOrder from "./Footer/HowtoOrder";
import QualityStandards from './Footer/QualityStandards';
import PrivacyPolicy from "./Footer/PrivacyPolicy";
import ReturnPolicy from "./Footer/ReturnPolicy";
import AboutUs from "./Footer/AboutUs";
import Terms_Condition from "./Footer/Terms_Condition";

import ScrollToTop from "./HomePage/ScrollToTop"

function App() {



  return (
    <div className="App">
        <Router>
        <ScrollToTop />
          <Routes>
            <Route path="/image" element={<ImageUpload />} />



            {/* FOOTER LINKS  */}
            <Route path="/howtoorder" element={<HowtoOrder />} />
            <Route path="/aboutus" element={<AboutUs />} />
            <Route path="/quality" element={<QualityStandards />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/return" element={<ReturnPolicy />} />
            <Route path="/termsCondition" element={<Terms_Condition />} />

            {/* ADMIN ROUTES */}
            <Route path="/admin/login" element={<AdminLogin />} />

            <Route element={<AdminRoute />}>
              <Route path="/" element={<AdminHomePage />} />
              <Route path='/admin/productupload/fruitveggie' element={<FruitVeggieUploadForm />} />
              <Route path='/admin/productupload/fish' element={<FishUploadForm />} />
              <Route path='/admin/productupload/finnospecial' element={<FinnoSpecialUploadForm />} />
              <Route path="/admin/viewproducts" element={<ViewProducts />} />
              <Route path="/admin/editproduct/:product_id" element={<EditProduct />} />
              <Route path="/admin/homepage" element={<AdminHomePage />} />
              <Route path="/admin/currentorders" element={<CurrentOrders />} />
              <Route path="/admin/approvedorders" element={<ApprovedOrders />} />
              <Route path="/admin/cancelledorders" element={<CancelledOrders />} />
              <Route path="/admin/deliveredorders" element={<DeliveredOrders />} />
              <Route path="/admin/refundList" element={<RefundList />} />
              <Route path="/admin/refundedorders" element={<RefundedOrders />} />
              <Route path='/admin/issue-refund/:order_id' element={<IssueRefundForm />} />
            </Route>



            <Route path="/underconstruction" element={<UnderConstruction />} />

          </Routes>
          <Footer />
        </Router>
    </div>
  );
}
export default App;
