import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const IssueRefundForm = () => {
  const { order_id } = useParams();
  const [orderDetails, setOrderDetails] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [refundAmount, setRefundAmount] = useState(0);

  const handleProductSelect = (productId, productPrice, productQuantity) => {
    const isSelected = selectedProducts.includes(productId);
    if (isSelected) {
      setSelectedProducts(selectedProducts.filter((id) => id !== productId));
      setRefundAmount(refundAmount - productPrice * productQuantity);
    } else {
      setSelectedProducts([...selectedProducts, productId]);
      setRefundAmount(refundAmount + productPrice * productQuantity);
    }
  };

  const handleIssueRefund = async (e) => {
    try {
      e.preventDefault();
      let refundType = "";
      if (selectedProducts.length === orderDetails.products.length)
        refundType = "Full";
      else refundType = "Partial";
      const data = {
        payment_id: orderDetails.payment_id,
        amount: refundAmount,
      };
      if (orderDetails.paymentMode === "Cash on Delivery") {
        const refundedProductsArray = selectedProducts.map((productId) => ({
          product_id: productId,
        }));
        const refundData = {
          refund_id: "Cash_" + Math.random() * 10000000000,
          status: "Refunded",
          refund_type: refundType,
          refund_amount: refundAmount,
          refunded_products: refundedProductsArray,
          order_id,
        };
        try {
          const dbResponse = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/order/update-refund-list`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(refundData),
            }
          );
          const respData = await dbResponse.json();
          if (dbResponse.status === 200) {
            toast.success("Refund Issued successfully");
          } else {
            toast.error("Some error Occured");
          }
        } catch (error) {
          toast.error("Some error Occured");
        }
      } else {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/payment/refund_checkout`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }
        );
        const resp_data = await response.json();
        if (resp_data.status === 200) {
          const refundedProductsArray = selectedProducts.map((productId) => ({
            product_id: productId,
          }));
          const refundData = {
            refund_id: resp_data.refund_order.id,
            status: "Refunded",
            refund_type: refundType,
            refund_amount: refundAmount,
            refunded_products: refundedProductsArray,
            order_id,
          };
          try {
            const dbResponse = await fetch(
              `${process.env.REACT_APP_BACKEND_URL}/order/update-refund-list`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(refundData),
              }
            );
            const respData = await dbResponse.json();
            if (respData) {
              toast.success("Refund Issued successfully");
            }
          } catch (error) {
            toast.error("Some error Occured");
          }
        } else {
          toast.error(
            "Refund failed to processed. Try again after some time!!"
          );
        }
      }
    } catch (error) {
      toast.error("Some error occurred");
    }
  };
  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/order/fetch-refund-order-details?order_id=${order_id}`
        ); // Update the API endpoint
        const data = await response.json();
        setOrderDetails(data.order[0]);
      } catch (error) {
        console.error("Error fetching order details:", error);
      }
    };

    fetchOrderDetails();
  }, [order_id]);
  return (
    <div
      style={{
        fontSize: "1.5em",
        backgroundColor: "white",
        boxShadow:
          "rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em",
        width: "75vw",
        marginLeft: "auto",
        marginRight: "auto",
        borderRadius: "1em",
        marginTop: "18vh",
        marginBottom: "25vh",
        paddingLeft: "3vw",
        paddingRight: "3vw",
        paddingTop: "5vh",
        paddingBottom: "5vh",
      }}
    >
      <h2 style={{ textAlign: "center", borderBottom: "0.1em solid black" }}>
        Issue Refund for Order : {order_id}
      </h2>
      {orderDetails ? (
        <form style={{ width: "100%" }}>
          <label>Order ID: {orderDetails.order_id}</label>
          <br />
          <label>Order Date: {orderDetails.orderPlaced_date}</label>
          <br />
          <label>Delivery Address: {orderDetails.delivery_address}</label>
          <br />
          <label>Payment Mode: {orderDetails.paymentMode}</label>
          <br />
          <label>Payment ID: {orderDetails.payment_id}</label>
          <br />
          <label>Customer Mobile No : {orderDetails.mobileNo}</label>
          <br />
          <h3>Products Ordered by Customer</h3>
          <ul>
            {orderDetails.products.map((product) => (
              <li key={product.product_id}>
                <strong>Product ID:</strong> {product.product_id}
                <br />
                <strong>Product Name:</strong> {product.product_name}
                <br />
                <strong>Piece Size:</strong> {product.piece_size}
                <br />
                <strong>Cut-Type:</strong> {product.cut_type}
                <br />
                <strong>Quantity:</strong> {product.product_quantity}
                <br />
                <strong>Price:</strong> {product.product_price}
                <br />
                <strong>Total Amount:</strong>{" "}
                {product.product_price * product.product_quantity}
              </li>
            ))}
          </ul>

          <h3>Refund Details</h3>
          <label htmlFor="refundAmount">Refund Amount (in Rs):</label>
          <input type="text" id="refundAmount" value={refundAmount} readOnly />

          <label htmlFor="selectedProduct">Select Products for Refund:</label>
          {orderDetails.products.map((product) => (
            <div
              style={{ display: "flex", alignItems: "center" }}
              key={product.product_id}
            >
              <input
                type="checkbox"
                id={`product-${product.product_id}`}
                name="selectedProducts"
                value={product.product_id}
                onChange={() =>
                  handleProductSelect(
                    product.product_id,
                    product.product_price,
                    product.product_quantity
                  )
                }
              />
              <label
                htmlFor={`product-${product.product_id}`}
                style={{ marginLeft: "8px" }}
              >
                Product ID : {product.product_id}
                <br />
                Product Amount : Rs.{" "}
                {product.product_price * product.product_quantity}
              </label>
            </div>
          ))}
          <button
            className="refund-issue-btn"
            onClick={(e) => handleIssueRefund(e)}
            style={{ marginTop: "2em", cursor: "pointer" }}
            disabled={refundAmount > 0 ? false : true}
          >
            Issue Refund
          </button>
        </form>
      ) : (
        <p>Loading order details...</p>
      )}
      <ToastContainer />
    </div>
  );
};

export default IssueRefundForm;
