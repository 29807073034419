import React from 'react';
import '../CSSStyling/aboutus.css';


function AboutUs() {
    const images = [
        "https://res.cloudinary.com/dqbxgjov1/image/upload/v1706685064/FooterMedia/ucz7t3p1pgyleo4yfyn7.jpg",
        "https://res.cloudinary.com/dqbxgjov1/image/upload/v1706685034/FooterMedia/kvy5m5vyeypjofqfjegh.jpg",
        "https://res.cloudinary.com/dqbxgjov1/image/upload/v1701983144/aboutusVeg_wdl0dh.jpg",
        "https://res.cloudinary.com/dqbxgjov1/image/upload/v1701983144/aboutusCommitment_tybywd.jpg"
    ];
    return (
        <div>
            
        <div className='aboutus_container'>
            <h3 className='aboutus_heading'>Welcome to Finno Farms - Farms to Market & Table - Delivering Freshness EveryDay</h3>
            <div className=' para'>
                <p>
                    At Finno Farms, we are passionate about delivering more than just food – we bring a commitment to quality, health, and sustainability directly to your doorstep. Founded on the principles of providing fresh and responsibly sourced produce, we take pride in connecting you with the very best from the farm to your home.
                </p>
            </div>
            <div className=' para'>
                <img src={images[0]} className='para_img' height={300} width="auto" />
                <p className='para_text'>
                    <span className='para_heading'>Fresh from the Sea:</span>Fresh from the Sea: Our commitment to freshness extends from the depths of the ocean to the heart of your kitchen. Enjoy a diverse selection of premium fish, caught and delivered with care. From delicate flavors to robust profiles, our seafood is a celebration of the ocean’s bounty.
                </p>
            </div>
            <div className='para'>
                <img src={images[1]} className='para_img' height={300} width="auto" />
                <p className='para_text'>
                    <span className='para_heading'>Fruits of the Earth:</span>Experience the natural sweetness and vibrant colors of farm-fresh fruits. Handpicked and delivered at their peak, our fruits are a burst of nutrition and flavor. Savor the goodness of nature with every bite.
                </p>
            </div>
            <div className='para'>
                <img src={images[2]} className='para_img' height={300} width="auto" />
                <p className='para_text'>
                    <span className='para_heading'>Vegetables with a Difference:</span> Elevate your culinary creations with our crisp and flavorful vegetables. Grown with care and delivered with precision, our veggies are a testament to the richness of the soil and the dedication of our partner farmers.
                </p>
            </div>
            <div className='para' style={{ marginTop: "2em" }}>
                Our Commitment to You: Finno Farms is more than a delivery service; we are your partners in a lifestyle that values health, taste, and convenience. We prioritize sustainability, supporting local farmers, and ensuring that your choices make a positive impact on both your well-being and the environment.
                <br/>
                <br/>
                <br/>
                🚀 Join the Finno Farms Family: Whether you're a seasoned chef, a health enthusiast, or someone who simply appreciates the joy of fresh, high-quality food, Finno Farms is here for you. Explore our website, make your selections, and let us bring the farm-fresh goodness to your door.
            </div>
            <div className='commitment' >
                <img src={images[3]} height={200} width={200} />
            </div>

            <div className='para'>
                🌐 Experience the Finno Farms Difference: Dive into a world where freshness isn’t just a promise; it's a way of life. Join us in redefining the way you eat, live, and enjoy the finest from land and sea. Welcome to Finno Farms – Your Fresh Lifestyle Awaits!
            </div>
        </div>
        
        </div>
    )
}

export default AboutUs