import React, { useEffect } from 'react';
import '../CSSStyling/Admin/adminHomepage.css';
import { Link, useNavigate } from 'react-router-dom';

function AdminHomePage() {
  const Navigate  = useNavigate();

  useEffect(() => {
    // Check if adminToken is present in localStorage
    const adminToken = localStorage.getItem('adminToken');

    if (!adminToken) {
      // Redirect to login page if adminToken is not present
      Navigate('/admin/login');
    }

    // Cleanup function
    return () => {
      // Any cleanup logic if needed
    };
  }, [Navigate]);

  return (
    <div>
      <div className='admin-home-page-container'>
        <div className='admin-page-navbar'>
          <div className='admin-page-heading'>Welcome FinnoFarms Admin</div>
          <button className='login-btn-admin' onClick={()=>{
            alert("Log out successful!!");
            localStorage.removeItem("adminToken");
            Navigate('/admin/login');
          }}>LOG OUT</button>
        </div>
        <div className='admin-controls-container'>
          <Link to="/admin/productupload/fruitveggie"><li className='admin-control'>Add new Fruit/Vegetable Product</li></Link>
          <Link to="/admin/productupload/fish"><li className='admin-control'>Add new Fish product</li></Link>
          <Link to="/admin/productupload/finnospecial"><li className='admin-control'>Add new FinnoSpecial product</li></Link>
          <Link to="/admin/viewproducts"><li className='admin-control'>Search the existing Product Database</li></Link>
          <Link to="/admin/viewproducts"><li className='admin-control'>Edit/Delete an existing Product</li></Link>
          <Link to="/admin/currentorders"><li className='admin-control'>View current orders</li></Link>
          <Link to="/admin/approvedorders"><li className='admin-control'>View approved orders</li></Link>
          <Link to="/admin/cancelledorders"><li className='admin-control'>View cancelled orders</li></Link>
          <Link to="/admin/deliveredorders"><li className='admin-control'>View delivered orders</li></Link>
          <Link to="/admin/refundlist"><li className='admin-control'>Orders to be Refunded</li></Link>
          <Link to="/admin/refundedorders"><li className='admin-control'>Refunded Orders</li></Link>
        </div>
      </div>
    </div>
  )
}

export default AdminHomePage