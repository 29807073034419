import React, { useState } from 'react'
import '../CSSStyling/FAQ.css';
function FAQ_Component(props) {
    const [isOpen, setOpen] = useState(false);
    return (
      <div className="FAQ_container">
        <div
          className={`FAQ_title ${isOpen ? "open" : ""}`}
          onClick={() => setOpen(!isOpen)}
          >
          {props.question}
        </div>
        <div className={`FAQ_item ${!isOpen ? "collapsed" : ""}`}>
          <div className="FAQ_content">{props.answer}</div>
        </div>
      </div>
    );
  };

export default FAQ_Component