import React from 'react'

import '../CSSStyling/footer.css'

function ReturnPolicy() {
    return (
        
    <div>
    
        <div style={{marginLeft:"15vw",marginRight:"15vw" , fontSize:"125%", marginBottom:"20vh"}} className='font-priv-size'>
            <h2>Return Policy</h2>
            <p>
                We have a simple return policy which entitles all our customers to return the product at the time of delivery if due to some reason they are not satisfied with the quality or freshness of the product or services from Finnofarms.in
                <br />
                <br />
                We will take the returned product back and issue another set of the same product if available. In case a few products are returned back by the customer during the time of delivery, the price of returned product will be deducted from the invoice in delivery time.
                <br />
                <br />
                If the customer is not willing to accept the product again or cancels order completely,<span style={{color:"red"}}> a credit into your Bank account for the same amount (excluding GST) will be made which can be used for your future purchases at Finnofarms.in.</span> The amount of the product (excluding GST) if requested to be credited to the bank Account will be credited within 4-5 business working days from the date of return initiated.
                <br />
                <br />
                After delivery we will not entertain any issue related to these products. Company also reserves the right to decide upon the return policy and can withdraw/modify the policy at any given time without any prior notice. Any misuse of the Return Policy will not be entertained and suitable action will be taken thereupon.
                <br/>
                <br/>
                For more refund/replacement queries or support, call at our customer care number - <u>+91 8100533280</u> or write mail at <u>finnofarms@gmail.com</u>
            </p>
            <br/>
            <br/>
            <br/>
            <br/>
        </div>
        </div>
    )
}

export default ReturnPolicy;