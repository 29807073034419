import React from 'react'

import '../CSSStyling/footer.css'

function QualityStandards() {
    return (
        <>
        
            <div style={{ marginBottom: "15vh", marginLeft: "15vw", marginRight: "15vw" }} className='font-priv-size'>
                <h1 style={{ color: "#889c0b", textDecoration: "underline", textAlign: "center" }}>Quality Standards</h1>
                Finnofarms.in brings you the next level of freshness with our broad range of farm fresh fish and horticulture products that will delight and warrant a healthier lifestyle choice for you. We are committed to high quality, fresh products and transparency with our customers. Our extensive supply network helps us deliver the freshest lot to your kitchen door.
                For us, quality is an in-built process designed to cater to your requirements and followed by food safety and regulatory norms. We take precautions to ensure that our products meet the highest standards of quality and the procedures we have in place are rigorous and consistently followed.
                Our team of experts inspect the food products at every stage, from supply to delivery, ensuring that our products are 100% premium quality and fresh.
            </div>

        </>
    )
}

export default QualityStandards