import React, { useEffect, useState } from 'react';
import { Outlet, Navigate } from 'react-router-dom';

const AdminRoute = ({ children, ...rest }) => {
  const [auth, setAuth] = useState(null);

  useEffect(() => {
    const adminToken = localStorage.getItem('adminToken');

    if (adminToken) {
      setAuth(true);
    } else {
      setAuth(false);
    }
  }, []);

  if (auth === null) {
    // Loading state, you can replace this with a loading indicator
    return <div>Loading...</div>;
  }

  return auth ? <Outlet /> : <Navigate to="/admin/login" />;
};

export default AdminRoute;
