import React, { useState } from 'react'

function FishUploadForm() {
    const [fishSizes, setFishSizes] = useState([]);
    const [cuttingTypes, setCuttingTypes] = useState('');
    const [unavailablePincodes, setUnavailablePincodes] = useState('');
    const [sizeAndPrice, setSizeAndPrice] = useState('');
    const [productData, setProductData] = useState({
        product_id: "",
        product_name: "",
        product_category: "",
        product_type: "",
        isWhole: "",
        product_quantity: "",
        view_more: "",
        calories: "",
        stock: 0,
        carbohydrate: "",
        protein: "",
        fat: "",
        country: "",
        description: "",
        images: []
    });
    const [displayImage, setDisplayImage] = useState("");
    const [image, setImage] = useState("");
    const [url, setUrl] = useState("");
    const handleCuttingChange = (e) => {
        let cuttings = e.target.value;
        const cutting = cuttings.split(',').map(cut => cut.trim());
        setCuttingTypes(cutting);
    }
    const handleUPincodeChange = (e) => {
        let pincodes = e.target.value;
        const pincode = pincodes.split(',').map(cut => cut.trim());
        setUnavailablePincodes(pincode);
    }
    const handleFishSizeChange = (e) => {
        let fishSiz = e.target.value;
        if (!fishSiz.includes("NA")){
            const fishSize = fishSiz.split(',').map(siz => siz.trim());
            setFishSizes(fishSize);
        } else {
            setFishSizes([]);
        }
    }
    const handleSizeandPrice = (e) => {
        let size_price = e.target.value;
        const sizeprice = size_price.split(', ').map((item) => {
            const [key, value] = item.split(' ');
            return [key, parseInt(value, 10)];
        });
        setSizeAndPrice(sizeprice);
    };
    const handleImageChange = async (e) => {
        e.preventDefault();
        const file = e.target.files[0];
        await setImage(file);
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setDisplayImage(reader.result);
            };
            reader.readAsDataURL(file);
        }


    };
    const uploadImage = async (e) => {
        e.preventDefault();
        const data = new FormData();
        const upload_preset = process.env.REACT_APP_CLOUDINARY_FISHPRODUCTUPLOAD_PRESET;
        const cloud_name = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME;
        data.append("file", image)
        data.append("upload_preset", upload_preset)
        data.append("cloud_name", cloud_name)
        await fetch(`https://api.cloudinary.com/v1_1/${cloud_name}/image/upload`, {
            method: "post",
            body: data
        })
            .then(resp => resp.json())
            .then(data => {
                setUrl(data.url);
                alert(`Image upload successful!!`);
            })
            .catch(err => console.log(err))
    }

    const handleChange = (e) => {
        let field = e.target.name;
        let value = e.target.value;
        setProductData({ ...productData, [field]: value });
    }
    const handleFormSubmit = (e) => {
        e.preventDefault();
        const formData = {
            product_id: productData.product_id,
            product_name: productData.product_name,
            product_category: productData.product_category,
            product_type: productData.product_type,
            product_quantity: productData.product_quantity,
            isWhole: (productData.isWhole === "True" || productData.isWhole === "true"),
            fishSize: fishSizes,
            cuttings: cuttingTypes,
            unavailable_pincodes: unavailablePincodes,
            size_price: sizeAndPrice,
            description: productData.description,
            country: productData.country,
            view_more: productData.view_more,
            calories: productData.calories,
            stock: productData.stock,
            carbohydrate: productData.carbohydrate,
            protein: productData.protein,
            fat: productData.fat,
            images: url
        };


        fetch(`${process.env.REACT_APP_BACKEND_URL}/fishUpload`, {
            method: "POST",
            headers: {
                'Content-Type': "application/json",
            },
            body: JSON.stringify(formData),
        })
            .then((response) => {
                if (response.ok) {
                    return response.json(); // Parse the response JSON if the status is okay (2xx)
                } else {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
            })
            .then((data) => {
                // Handle successful response
                window.alert("Product Upload successful!");
                window.location.reload();
            })
            .catch((error) => {
                // Handle errors
                console.error('Error:', error);
                window.alert("Error occurred in uploading. Try Again!!");
            });

    }
    return (
        <div className='main-container'>
            <div className='product-form-container'>
                <h2>Add New Fish Product</h2>
                <form className='product-form' onSubmit={handleFormSubmit}>
                    <div className='form-group'>
                        <div className="fileupload-container">
                            <div className="row justify-content-center m-0">
                                <div className="col-md-6">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="kb-data-box">
                                                <div className="image-upload-heading">
                                                    <h6>Upload Product Images here</h6>
                                                </div>
                                                <form>
                                                    <div className="file-upload-container">
                                                        <div className="file-upload-box">
                                                            <input
                                                                type="file"
                                                                id="fileupload"
                                                                className="file-upload-input"
                                                                // onChange={(e) => setImage(e.target.files[0])}
                                                                onChange={handleImageChange}
                                                            />
                                                            <span>
                                                                Drag and drop or{' '}
                                                                <span className="file-link">Choose your files</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    {displayImage && (
                                                        <div className="image-display-container">
                                                            <img src={displayImage} alt="Preview" height={200} width={200} />
                                                        </div>
                                                    )}
                                                    <button className='upload-img-btn' onClick={uploadImage}>Upload</button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='form-group'>
                        <input type="text" name='product_id' className='form-input' placeholder='Product ID' value={productData.product_id} onChange={handleChange} />
                    </div>
                    <div className='form-group'>
                        <input type="text" name='product_name' className='form-input' placeholder='Product Name' value={productData.product_name} onChange={handleChange} />
                    </div>
                    <div className='form-group'>
                        <input className='form-input' name='product_type' type='text' placeholder='Product Type' onChange={handleChange} />
                    </div>
                    <div className='form-group'>
                        <input className='form-input' name='product_category' type='text' placeholder='Product Category' onChange={handleChange} />
                    </div>
                    <div className='form-group'>
                        <input className='form-input' name='isWhole' type='text' placeholder='Whole Fish' onChange={handleChange} />
                        <br></br>
                        <p className='input-help'>NOTE: Input format ( e.g, True or False)</p>
                    </div>
                    <div className='form-group'>
                        <input className='form-input' name="product_quantity" type='text' placeholder='Product Quantity (in grams)' onChange={handleChange} />
                    </div>
                    <div className='form-group'>
                        <input type="text" className='form-input' name='fishsize' placeholder='Fish Sizes' value={fishSizes} onChange={handleFishSizeChange} />
                        <br></br>
                        <p className='input-help'>NOTE: Input format ( e.g, "1 Kg, 1.5 Kg, 2 Kg, 2.5 Kg" or none)</p>
                    </div>
                    <div className='form-group'>
                        <input type="text" className='form-input' name='cutting' placeholder='Cutting Types' value={cuttingTypes} onChange={handleCuttingChange} />
                        <br></br>
                        <p className='input-help'>NOTE: Input format ( e.g, "Gada, peti, whole")</p>
                    </div>
                    <div className='form-group'>
                        <input
                            type="text"
                            className='form-input'
                            name="sizePrice"
                            placeholder='Size and Price'
                            onChange={handleSizeandPrice}
                        />
                        <br />
                        <p className='input-help'>
                            NOTE: Input format ( e.g., "Large(50gm-60gm) 1550, Medium(30gm-40gm) 850"):
                        </p>
                    </div>
                    <div className='form-group'>
                        <textarea
                            className='form-input'
                            name="description"
                            placeholder='Product Description'
                            rows={3}
                            onChange={handleChange}
                        />
                    </div>
                    <div className='form-group'>
                        <input className='form-input' name='country' type='text' placeholder='Country Of Origin' onChange={handleChange} />
                    </div>
                    <div className='form-group'>
                        <input className='form-input' name='stock' type='number' placeholder='Product Stock' onChange={handleChange} />
                    </div>
                    <div className='form-group'>
                        <input type="text" className='form-input' name='pincode' placeholder='Unavailable Pincodes' value={unavailablePincodes} onChange={handleUPincodeChange} />
                        <br></br>
                        <p className='input-help'>NOTE: Input format ( e.g, "713211, 734101")</p>
                    </div>
                    <div className='form-group'>
                        <textarea
                            className='form-input'
                            name="view_more"
                            placeholder='View More text'
                            rows={3}
                            onChange={handleChange}
                        />
                    </div>
                    <div className='form-group'>
                        <input className='form-input' name='calories' type='text' placeholder='Calories' onChange={handleChange} />
                    </div>
                    <div className='form-group'>
                        <input className='form-input' name='carbohydrate' type='text' placeholder='Carbohydrates' onChange={handleChange} />
                    </div>
                    <div className='form-group'>
                        <input className='form-input' name='protein' type='text' placeholder='Protein' onChange={handleChange} />
                    </div>
                    <div className='form-group'>
                        <input className='form-input' name='fat' type='text' placeholder='Fat' onChange={handleChange} />
                    </div>
                    <button type='submit' className='add-product-btn'>Add Product</button>
                </form>
            </div>
        </div>
    );
}

export default FishUploadForm