import React from 'react'

import '../CSSStyling/footer.css'

function Terms_Condition() {
  return (
    <div>
      
      <div style={{ marginLeft:"10vw",marginRight:"10vw" }} className='font-priv-size'>
      <h2>TERMS AND CONDITIONS OF ONLINE FOOD ORDER</h2>

      <p>
        At the time of signup and thereafter you hereby agree to and therefore signify your acceptance and no-objection to ALL the terms and conditions of use of the website www.finnofarms.in which is wholly owned, operated and managed by Finno AQ Pvt Ltd (hereinafter referred to as 'Finnofarms.in'). As a visitor to the site you are advised to please read the Terms & Conditions carefully before using the services.
      </p>

      <h3 style={{textAlign:"left",fontSize:"1.3em"}}>Order Booking & Financial Terms:-</h3>
      <ul>
        <li>Finnofarms.in delivers the products on its specified time slots which is between 8:00 am to 2:00 pm. Delivery time can be delayed sometimes due to unavoidable circumstances like traffic congestion, roadblocks or any natural calamities.
          Final time of placing an order to get delivered the next day is 11 pm.</li>
        <li>
          All food orders will be delivered within 24 hours after placing the order. If there is a delay, we will contact you over your registered email or call.
        </li>
        <li>Delivery charge will be Rs. 39/- or 69/- for cart orders between Rs. 500 - 1499</li>
        <li> Minimum cart order value is Rs.500/- for home delivery of food items.</li>
        <li> For placing bulk orders for birthdays, events, marriage occasions etc. customers will be rewarded with an additional 10% discount on the cart value. Advanced orders will be taken in this case with full online mode of payment. In case of advanced orders, customers will be able to choose the time of delivery which is divided into under-mentioned slots.<br /> Time slot will be be:
          SLOT 1: 8 AM TO 1 PM</li>
        <li> Customers are liable to collect ordered products from the doorstep when food orders are delivered.</li>
        <li> If there are issues in food product quality or freshness during time of delivery, please contact our customer care team within 24 hours and share a video of the food product for return and replacement. We will initiate replacement within 30 hours of receiving the request. If cancelled, GST imposed over the bill value as per Govt. rules. will not be refunded under any circumstances.</li>
        <li>
          Current mode of payments are:
          <li>Cash on delivery</li>
          <li>Credit and debit cards (VISA / Mastercard)</li>
          <li>Upi</li>
          <li>Paytm/Google Pay</li>
          We do not accept Cheque or demand draft.<br />
          While availing any of the payment method/s offered at Finnofarms.in. Finnofarms.in will not be responsible or assume any liability, whatsoever in respect of any loss or damage arising directly or indirectly to you due to:
          <ol>
            <li>Lack of authorization for any transaction/s, or</li>
            <li>Exceeding the preset limit mutually agreed by you and between your “Bank/s”, or</li>
            <li>Any payment issues arising out of the transaction, or</li>
            <li>Decline of transaction for any other reason/s.</li>
          </ol>
          Finnofarms.in does not store any information pertaining to Credit/Debit card or online banking payments and all payments are made via our payment gateway partners. A Copy of your invoices will be provided at the time of your delivery.
        </li>
      </ul>
      <h3 style={{textAlign:"left",fontSize:"1.3em"}}>Cancellation, Return & Refund Policy:-</h3>
      <p>
        There will be 15 minutes time to cancel any order of food items from customer end before order confirmation is finalised. The amount of the product (excluding GST) will be credited to the wallet and or registered bank account within 4-5 business working days from the date of return initiated.
        Finnofarms.in also reserves the right to cancel any order without any explanation for doing so, under situations where we are not able to meet the requirement of the order placed or order so placed/cancelled becomes unavailable or does not comply with the Finnofarms.in policy or for any other reason. However we will ensure that any communication of cancellation of an order, so cancelled, is intimated within appropriate time to the concerned person and any applicable refund, will be made in reasonable time.
        Also for orders where problems arise owing to the mistake of the user, e.g. wrong address or incomplete information or recipient not reachable, no refund will be given. Redelivery charges, if any, will be borne by the user. So we advised you to check all the products at the time of delivery. After delivery we will not entertain any issue related to these products.
      </p>

      <h3 style={{textAlign:"left",fontSize:"1.3em"}}>Terms of service:-</h3>
      <ul>
        <li>You may not be able to avail our Services if your delivery location is outside our current scope of Service. We will keep you informed of the same at the time of confirming your order booking.
        </li>
        <li>
          You understand that delivery periods quoted to you at the time of confirming the order is an approximate estimate and may vary. We will not be responsible for any delay in the delivery of your order.
        </li>
        <li>
          Your order will be only delivered to the address designated by you at the time of placing the order on the Platform. We reserve the right to cancel the order, in our sole discretion, in the event of any change to the place of delivery and you shall not be entitled to any refund for the same. Delivery in the event of change of the delivery location shall be at our sole discretion.
        </li>
        <li>
          You shall undertake to provide adequate directions, information and authorisations to accept delivery. In the event of any failure to accept delivery, failure to deliver within the estimated time due to your failure to provide appropriate instructions, or authorizations, then such goods shall be deemed to have been delivered to you and all risk and responsibility in relation to such goods shall pass to you and you shall not be entitled to any refund for the same. Our decision in relation to this shall be final and binding.
        </li>
        <li>
          You understand that our liability ends once your order has been delivered to you.
        </li>
        <li>
          We do not offer any refunds against items already purchased from the Platform unless an error that is directly attributable to us has occurred during the purchase of such product or services.
        </li>
        <li>
          We constantly strive to provide you with accurate information on the Platform. However, in the event of an error, we may, in our sole discretion, contact you with further instructions.
        </li>
      </ul>


      <h3 style={{textAlign:"left",fontSize:"1.3em"}}>General terms of use:-</h3>
      <li>
        Services of the site would be available to only select geographics in India and Persons who are "incompetent to contract" within the meaning of the Indian Contract Act, 1872 including minors, un-discharged insolvents etc. are not eligible to use the Platform. Only individuals who are 18 years of age or older may use the Platform and avail Services. If you are under 18 years of age and you wish to download, install, access or use the Platform, your parents or legal guardian must acknowledge and agree to the Terms of Use and Privacy Policy. Should your parents or legal guardian fail to agree or acknowledge the Terms of Use and Meengal policies, you shall immediately discontinue its use. Meengal reserves the right to terminate your account and / or deny access to the platform if it is brought to Meengal's notice that you are under the age of 18 years.
      </li>
      <li>
        If you choose to use the Platform, it shall be your responsibility to treat your user identification code, password and any other piece of information that we may provide, as part of our security procedures, as confidential and not disclose the same to any person or entity other than us. We shall at times and at our sole discretion reserve the right to disable any user identification code or password if you have failed to comply with any of the provisions of these Terms of Use.
      </li>
      <li>
        By using the Platform you represent and warrant that:
        <ol>
          <li>
            All registration information you submit is truthful, lawful and accurate and that you agree to maintain the accuracy of such information.
          </li>
          <li>
            Your use of the Platform shall be solely for your personal use and you shall not authorise others to use your account, including your profile or email address and that you are solely responsible for all content published or displayed through your account, including any email messages, and your interactions with other users and you shall abide by all applicable local, state, national and foreign laws, treaties and regulations, including those related to data privacy, international communications and the transmission of technical or personal data.
          </li>
          <li>
            You shall not attempt to gain unauthorised access to portion or features of the finnofarms.in, or any other systems or network,connected to the or to any server,computer,network,or to any of the services offered on or through finnofarms.in  ,by hacking,password mining or any other illegitimate means.
          </li>
          <li>
            You will not submit, post, upload, distribute, or otherwise make available or transmit any content that: (a) is defamatory, abusive, harassing, insulting, threatening, or that could be deemed to be stalking or constitute an invasion of a right of privacy of another person; (b) is bigoted, hateful, or racially or otherwise offensive; (c) is violent, vulgar, obscene, pornographic or otherwise sexually explicit; (d) is illegal or encourages or advocates illegal activity or the discussion of illegal activities with the intent to commit them.
          </li>
          <li>
            All necessary licences, consents, permissions and rights are owned by you and there is no need for any payment or permission or authorization required from any other party or entity to use, distribute or otherwise exploit in all manners permitted by these Terms of Use and Privacy Policy, all trademarks, copyrights, patents, trade secrets, privacy and publicity rights and / or other proprietary rights contained in any content that you submit, post, upload, distribute or otherwise transmit or make available.
          </li>
          <li>
            You will not use the Platform in any way that is unlawful, or harms us or any other person or entity, as determined in our sole discretion.

          </li>
          <li>
            You will not post, submit, upload, distribute, or otherwise transmit or make available any software or other computer files that contain a virus or other harmful component, or otherwise impair or damage the Platform or any connected network, or otherwise interfere with any person or entity's use or enjoyment of the Platform.

          </li>
          <li>
            You will not use another person's username, password or other account information, or another person's name, likeness, voice, image or photograph or impersonate any person or entity or misrepresent your identity or affiliation with any person or entity.

          </li>
          <li>
            You will not engage in any form of antisocial, disrupting, or destructive acts, including "flaming," "spamming," "flooding," "trolling," and "griefing" as those terms are commonly understood and used on the Internet.

          </li>
          <li>
            You will not delete or modify any content of the Platform, including but not limited to, legal notices, disclaimers or proprietary notices such as copyright or trademark symbols, logos, that you do not own or have express permission to modify.

          </li>
          <li>
            You will not post or contribute any information or data that may be obscene, indecent, pornographic, vulgar, profane, racist, sexist, discriminatory, offensive, derogatory, harmful, harassing, threatening, embarrassing, malicious, abusive, hateful, menacing, defamatory, untrue or political or contrary to our interest.

          </li>
          <li>
            You shall not access the Platform without authority or use the Platform in a manner that damages, interferes or disrupts:

          </li>
          <li>
            any part of the Platform or the Platform software; or
          </li>
          <li>
            any equipment or any network on which the Platform is stored or any equipment of any third party

          </li>
        </ol>
      </li>
      <li>
        You release and fully indemnify Finnofarms.in and/or any of its officers and representatives from any cost, damage, liability or other consequence of any of the actions of the Users of the Platform and specifically waive any claims that you may have in this behalf under any applicable laws of India. Notwithstanding its reasonable efforts on that behalf, Finnofarms.in cannot take responsibility or control the information provided by other Users which is made available on the Platform. You may find other User's information to be offensive, harmful, inconsistent, inaccurate, or deceptive. Please use caution and practise safe trading when using the Platform.
      </li>



      <h3 style={{textAlign:"left",fontSize:"1.3em"}}>Access to the Platform, Accuracy and security:-</h3>
      <li>
        Finnofarms.in  does not represent that access to the Platform will be uninterrupted, timely, error free, free of viruses or other harmful components or that such defects will be corrected.
      </li>
      <li>
        We do not warrant that the Platform will be compatible with all hardware and software which you may use. We shall not be liable for damage to, or viruses or other code that may affect, any equipment (including but not limited to your mobile device), software, data or other property as a result of your download, installation, access to or use of the Platform or your obtaining any material from, or as a result of using, the Platform. We shall also not be liable for the actions of third parties.
      </li>
      <li>
        We do not represent or warranty that the information available on the Platform will be correct, accurate or otherwise reliable.
      </li>
      <li>
        We reserve the right to suspend or withdraw access to the Platform to you personally, or to all users temporarily or permanently at any time without notice. We may at any time at our sole discretion reinstate suspended users. A suspended User may not register or attempt to register with us or use the Platform in any manner whatsoever until such time that such user is reinstated by us.
      </li>

      <h3 style={{textAlign:"left",fontSize:"1.3em"}}>Disclaimer:-</h3>
      <p>
        This Website & the brand Finno Farms is owned by Finno AQ Pvt Limited. Finnofarms.in will not be liable for any damages of any kind arising from the use of this Website, or products acquired through this Website, including, but not limited to direct, indirect, incidental, punitive, and consequential damages. Pricing on this Website may be subject to change without notice.
        <br />
        1. Perishable products like fish and horticulture products shown on the website here are indicative. We do our best to give a better result than shown. It may be possible that actual arrangement varies due to availability and other reasons beyond our control. However, we always ensure full or higher value to our customers.
        <br />
        2.Delivery schedule varies 1-2 days post confirmation of the order, depending on the destination city or town. For the products, which require special procuring, delivery time may vary.
        <br />
        3.Products sold by Finnofarms.in on the website are strictly meant to be consumed in moderation, and Finnofarms will not be held responsible by any means for any mental or physical damage or loss of life or failure of any or all organ/s in the body.
      </p>

      <h3 style={{textAlign:"left",fontSize:"1.3em"}}>Limitation of Liability:-</h3>
      <p>In no event shall Finnofatms.in be liable for any special, incidental, indirect or consequential damages of any kind in connection with this agreement, even if Finnofarms.in has been informed in advance of the possibility of such damages.</p>

      <h3 style={{textAlign:"left",fontSize:"1.3em"}}>Governing Law and Jurisdiction:-</h3>
      <p>This Platform originates from Howrah, West Bengal. This Agreement will be governed by the laws of India and shall be subject to the exclusive jurisdiction of Courts in Howrah.</p>

      <h3 style={{textAlign:"left",fontSize:"1.3em"}}>Our Contact:-</h3>
      <p>
        Please send any questions or comments (including all inquiries unrelated to copyright infringement) to finnofarms@gmail.com or contact our customer care support number at +91 8100533280
      </p>

    </div>
    </div>
  )
}

export default Terms_Condition