import React from 'react'
import CopyRight from './CopyRight'
import '../CSSStyling/footer.css'
import FooterLinks from './FooterLinks'
function Footer() {
    return (
        <footer className='footer'>
            <FooterLinks />
            <CopyRight />
        </footer>
    )
}

export default Footer