import React, { useState } from 'react';
import '../CSSStyling/Admin/adminlogin.css';
import { useNavigate } from 'react-router-dom';
function AdminLogin() {
    const Navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/admin/signin`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username, password }),
            });

            if (!response.ok) {
                alert("Invalid Credentials!!");
                throw new Error('Invalid credentials');
            }

            const data = await response.json();
            if (data.success) {
                const authToken = data.authToken;
                localStorage.setItem('adminToken', authToken);
                Navigate('/admin')
            }
            else {
                alert("Not found!!");
            }
        } catch (error) {
            console.error('Login failed:', error.message);
        }
    };

    return (
        <div style={{ height: "100vh" }}>
            <h2>Admin Login</h2>
            <form className='admin-login-form' onSubmit={handleSubmit}>
                <label>
                    Username:
                    <input
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                    />
                </label>
                <br />
                <label>
                    Password:
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </label>
                <br />
                <button type="submit">Sign In</button>
            </form>
        </div>
    );
}

export default AdminLogin;
