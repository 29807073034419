import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import '../CSSStyling/Admin/editproduct.css';
function EditProduct() {
    const { product_id } = useParams();
    const [productDetails, setProductDetails] = useState();
    const [newImageUrl, setNewImageUrl] = useState('');
    const [image, setImage] = useState();
    const [displayImage, setDisplayImage] = useState();
    useEffect(() => {
        const fetchProductDetails = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/search/${product_id}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    console.error('Error fetching product details');
                    return;
                }

                const productData = await response.json();
                await setProductDetails(productData.productData);
            } catch (error) {
                console.error('Error fetching product details', error);
            }
        };
        fetchProductDetails();
    }, [product_id]);
    const handleEditProduct = async (e) => {
        const { product_id, product_type, ...editedData } = productDetails;
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/editproduct/${product_type}/${product_id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(editedData),
            });
            if (response.ok) {
                const data = await response.json();
                alert("Product edited successfully");
            } else {
                // Handle error
                alert("Some error has occured!! Try again!!");
                console.error('Error editing product:', response.status);
            }
        } catch (error) {
            console.error('Error editing product:', error);
        }
    }
    const handleImageUpload = async (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setDisplayImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
        await setImage(file);
    };
    const uploadImage = async (e) => {
        e.preventDefault();
        const data = new FormData();
        let upload_preset = "";
        if (productDetails.product_type === "Fish") {
            upload_preset = process.env.REACT_APP_CLOUDINARY_FISHPRODUCTUPLOAD_PRESET;
        }
        else {
            upload_preset = process.env.REACT_APP_CLOUDINARY_PRODUCTUPLOAD_PRESET;
        }
        const cloud_name = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME;
        data.append("file", image)
        data.append("upload_preset", upload_preset)
        data.append("cloud_name", cloud_name)
        await fetch(`https://api.cloudinary.com/v1_1/${cloud_name}/image/upload`, {
            method: "post",
            body: data
        })
            .then(resp => resp.json())
            .then(async (data) => {
                setNewImageUrl(data.secure_url);
                alert(`New Image uploaded successfully!!`);
                await setProductDetails(({
                    ...productDetails,
                    images: newImageUrl ? newImageUrl : data.secure_url
                }));
        
            })
            .catch(err => console.log(err))
    }

    const handlePrice = async (e) => {
        let val = e.target.textContent;
        let arr = val.split(", ");
        let newArr = [];
        for (let i = 0; i < arr.length; i++) {
            let temp = arr[i].split(" ");
            newArr.push({ size: temp[0], price: temp[1] });
        }
        await setProductDetails({ ...productDetails, ["size_price"]: newArr });
    }

    const handleUPincode = async (e) => {
        let val = e.target.textContent;
        let arr = val.split(",").map(cut => cut.trim());
        await setProductDetails({ ...productDetails, ["unavailable_pincodes"]: arr });
    }

    const renderValue = (value, key) => {
        if (typeof (value) !== 'object') {
            if (typeof (value) === 'string' && value.substring(0, 4) === "http")
                return (
                    <div className='image_edit_container'>
                        <img src={value} id={key} alt='Image' height={150} width={150} />
                        <input type="file" accept="image/*" onChange={handleImageUpload} />
                        {displayImage && (
                            <div>
                                <p>New Image Preview:</p>
                                <img src={displayImage} alt="New Image" height={150} width={150} />

                            </div>
                        )}
                        {displayImage && (
                            <button onClick={uploadImage}>Replace</button>
                        )}
                    </div>);
            else
                return <p id={key} contentEditable={true} onBlur={async (e) => {
                    await setProductDetails({ ...productDetails, [e.target.id]: e.target.textContent });
                }}>{value}</p>
        }
        else {
            let arr = [];
            arr = Array.from(value);
            if (key === 'unavailable_pincodes'){
                return <p  contentEditable={true} onBlur={async (e) => {
                    await handleUPincode(e);
                }}>{arr.join(', ')}</p>;}
            else if (typeof (arr[0]) !== "object"){
                return <span>{arr.join(', ')}</span>;}
            else {
                let val = "";
                for (let i = 0; i < arr.length; i++) {
                    if (i === arr.length - 1)
                        val = val + arr[i].size + " " + arr[i].price;
                    else
                        val = val + arr[i].size + " " + arr[i].price + ", ";
                }
                return <p contentEditable={true} onBlur={async (e) => {
                    await handlePrice(e);
                }}>{val}</p>
            }
        }
    };
    return (
        <div>
            <h2>Edit Product</h2>
            <table className='edit-product-table-container'>
                <thead>
                    <tr>
                        <th>Input field</th>
                        <th>Value</th>
                    </tr>
                </thead>
                <tbody>
                    {productDetails && Object.keys(productDetails).map((key) => {
                       
                        if (key === '__v' || key === '_id') {
                            return null;
                        }

                        return (
                            <tr key={key}>
                                <td>{key}:</td>
                                <td>{renderValue(productDetails[key], key)}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <button style={{marginLeft:"10vw", fontSize:"1em",paddingLeft:"0.5em",paddingRight:"0.5em",marginTop:"5vh",marginBottom:"10vh",width:"80vw"}} onClick={handleEditProduct}>Save Edit</button>
        </div>
    );
}

export default EditProduct