import React from 'react';
import { Link } from 'react-router-dom';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';


function FooterLinks() {
  const scrollToTop = () => {
    if ('scrollBehavior' in document.documentElement.style) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    } else {
      window.scrollTo(0, 0);
    }
  };
  return (
    <div className='footer_links_container'>
      <div className='useful_links_container'>
        <h3>USEFUL LINKS</h3>
        <Link to="/aboutus" onClick={scrollToTop}><li>About Us</li></Link>
        <Link to="/termsCondition" onClick={scrollToTop}><li>Terms & Conditions</li></Link>
        <Link to="/privacy" onClick={scrollToTop}><li>Privacy Policy</li></Link>
        <Link to="/return"  onClick={scrollToTop}><li>Refund & Return Policy</li></Link>
        <Link to="/quality" onClick={scrollToTop}><li>Quality Standards</li></Link>
        <Link to="/howtoorder" onClick={scrollToTop}><li>How to Order?FAQs</li></Link>
      </div>
      <div className='contact_links'>
        <h3>CONTACT US</h3>
        <li>Address: 25, Guitendal Ln, Kadam Tala</li>
        <li>Howrah - 711101</li>
        <li>Phone: +91 8100533280</li>
        <li>Email: finnofarms@gmail.com</li>
      </div>
      <div className='socialmedia_links'>
        <Link to="https://www.facebook.com/profile.php?id=61553337273743&mibextid=9R9pXO"><li><FacebookIcon /></li></Link>
        <Link to="https://www.instagram.com/finnofarms?igsh=Nmo0c21nZWRnYnd5"><li><InstagramIcon /></li></Link>
        <Link to="https://www.linkedin.com/company/finnoaq/"><li><LinkedInIcon /></li></Link>
      </div>
    </div >
  )
}

export default FooterLinks